<template>
  <div class="card full-width">

    <el-dialog v-model="dialogVisible" title="Wallet" width="30%">
      <el-form>
        <div class="row g-9 mb-7">
          <div class="col-md-12 fv-row">
            <label class="required fs-6 fw-bold mb-2">Type</label>
            <el-form-item>
              <el-select v-model="walletTransaction.type" class="select-display-block">
                <el-option v-for="walletOption in walletOptions" :label="walletOption.label" :value="walletOption.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-12 fv-row">
            <label class="required fs-6 fw-bold mb-2">Price</label>
            <el-form-item >
              <el-input @keyup.enter="makeTransaction" v-model="walletTransaction.price" placeholder="Price"/>
            </el-form-item>
          </div>
        </div>



      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" :loading="transLoading" @click="makeTransaction">Save</el-button>

      </span>
      </template>
    </el-dialog>
    <div class="card-body pt-0">
      <div class="card-header border-0 pt-5"><h3 class="card-title align-items-start flex-column"><span
          class="card-label fw-bold fs-3 mb-1">Wallet Transactions</span><span class="fs-5" style="color: #0d8ddc">Balance = {{wallet}}</span>
      </h3>
      </div>
      <div class="row" v-if="enableMakeTransaction">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <a href="javascript:;" class="btn btn-sm btn-primary" id="kt_toolbar_primary_button" @click="dialogVisible = true"> Withdraw And Deposit </a>
        </div>
      </div>

      <el-empty v-if="!loading && tableData.length <= 0" description="No Results Found"/>
      <Datatable v-if="tableData.length > 0"
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
        <template v-slot:cell-type_text="{ row: data }">{{ data.type_text }}</template>
        <template v-slot:cell-price_before="{ row: data }">{{ data.price_before }}</template>
        <template v-slot:cell-price="{ row: data }">{{ data.price }}</template>
        <template v-slot:cell-price_after="{ row: data }">{{ data.price_after }}</template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {useRouter, useRoute} from 'vue-router'

export default defineComponent({
  name: "wallet-transactions-list",
  components: {
    Datatable, CheckboxTableHeader
  },
  props: ['id' , 'url' , 'walletOptions' , 'enableMakeTransaction'],
  setup(props) {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "Type", key: "type_text", sortable: false,},
      {name: "Price Before", key: "price_before", sortable: true,},
      {name: "Price", key: "price", sortable: true,},
      {name: "Price After", key: "price_after", sortable: false,},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      page: 1,
      per_page: 10
    });
    const wallet = ref(0);
    const walletTransaction = ref({
      type : '',
      price : ''
    });
    const dialogVisible = ref(false);
    const transLoading = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Wallet Transactions", []);
    });

    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(props.url, filters.value);
        let data = response.data.data;
        tableData.value = data.wallet_transactions;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        wallet.value = data.wallet;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }
    const  makeTransaction = async () => {
      transLoading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.post(props.url, walletTransaction.value);
        transLoading.value = false;
        handleSuccess(response);
        fetchData();
        dialogVisible.value = false;
      } catch (error) {
        transLoading.value = false;
        handleError(error)
      }
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });


    fetchData();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      wallet,
      walletTransaction,
      makeTransaction,
      transLoading,
      dialogVisible

    };
  },

});
</script>
