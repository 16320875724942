<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">First Name</label>
            <el-form-item prop="first_name" :rules="rules['field']">
              <el-input v-model="objData.first_name" placeholder="First Name"/>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Last Name</label>
            <el-form-item prop="last_name" :rules="rules['field']">
              <el-input v-model="objData.last_name" placeholder="Last Name"/>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Email</label>
            <el-form-item prop="email" :rules="rules['field']">
              <el-input v-model="objData.email" placeholder="Email"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-1 fv-row phone-code" >
            <label class="required fs-6 fw-bold mb-2">Code</label>
            <el-form-item prop="phone_code" :rules="rules['field']">
              <el-select v-model="objData.phone_code" placeholder="Select" style="width: 110px">
                <el-option v-for="phoneCode in phoneCodes" :label="phoneCode.phone_code" :value="phoneCode.phone_code"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Phone</label>
            <el-form-item prop="phone" :rules="rules['field']">
              <el-input v-model.number="objData.phone" placeholder="Phone"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Cities</label>
            <el-form-item prop="city_ids" :rules="rules['field']">
              <el-cascader class="full-width" v-model="objData.city_ids" placeholder="Select Cities"
                           :options="countries" :props="{multiple: true}" collapse-tags clearable filterable/>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Password</label>
            <!-- :rules="type == 'create' ? rules['field'] : ''" -->
            <el-form-item prop="password" :rules="rules['password']">
              <el-input v-model="objData.password" type="password" show-password placeholder="Password"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">

          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Provider Ratio</label>
            <el-form-item prop="provider_rate" :rules="rules['rate']">
              <el-input v-model="objData.provider_rate" placeholder="%" class="mb-2" style="width: 80px"></el-input>
            </el-form-item>
            <el-progress type="circle" :percentage="objData.provider_rate"  />
          </div>
          <image-comp :element="objData.image" v-model="objData.image" col="col-md-3 fv-row" title="Image"></image-comp>
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4">
            <image-comp :element="objData.identity_card" v-model="objData.identity_card" col="col-md-6 fv-row"
                        title="Identity Card"></image-comp>
            <div class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.identity_card_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="identity_card_status" :rules="rules['field']">
                <el-select v-model="objData.identity_card_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-4">
            <image-comp :element="objData.driver_license" v-model="objData.driver_license" col="col-md-6 fv-row"
                        title="Driver License"></image-comp>
            <div class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.driver_license_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="driver_license_status" :rules="rules['field']">
                <el-select v-model="objData.driver_license_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-4">
            <image-comp :element="objData.vehicle_license" v-model="objData.vehicle_license" col="col-md-6 fv-row" title="Vehicle License"></image-comp>
            <div class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.vehicle_license_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="vehicle_license_status" :rules="rules['field']">
                <el-select v-model="objData.vehicle_license_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <hr v-if="objData.bank_accounts && objData.bank_accounts.length > 0">
        <h3 v-if="objData.bank_accounts && objData.bank_accounts.length > 0">Bank Accounts : </h3>
        <div v-if="objData.bank_accounts && objData.bank_accounts.length > 0" class="row g-9 mb-7">
          <div class="col-md-8">
            <div class="table-responsive">
              <table class="table gs-7 gy-7 gx-7">
                <thead>
                <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Bank</th>
                  <th>Account Number</th>
                  <th>IBAN</th>
                  <th>Swift Code</th>
                  <th>File</th>
                </tr>
                </thead>

                <tr v-for="bank_account in objData.bank_accounts">
                  <td v-text="bank_account.bank"></td>
                  <td v-text="bank_account.account_number"></td>
                  <td v-text="bank_account.iban"></td>
                  <td v-text="bank_account.swift_code"></td>
                  <td>
                    <div class="demo-type" >
                      <el-image v-if="bank_account.file"
                                class="image-in-list"
                                :src="bank_account.file"
                                :preview-src-list="[bank_account.file]"
                                :initial-index="1"
                                fit="cover"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>

        </div>
        <hr>
        <h3 v-if="objData.services && objData.services.length > 0">Services : </h3>
        <div class="row g-9 mb-7" v-for="(service,index) in objData.services">
          <h4>{{ (index + 1) + " - " + service.name }}</h4>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <div class="row ">
                <div class="col-md-6 mt-4 fv-row" v-for="(option,optionIndex) in service.options">
                  <h5>{{ option.name }}</h5>
                  <el-checkbox v-model="option.include_smaller" label="Include smaller" size="large"/>
                  <el-checkbox v-model="option.is_required" label="Is required" size="large"/>
                  <draggable
                      tag="ul"
                      :list="option.values"
                      class="list-group"
                      handle=".handle"
                      item-key="item_key"
                  >
                    <template #item="{ element, index }">
                      <li class="list-group-item" :class="element.is_selected ? 'selected-item' : ''">
                        <i class="fa fa-align-justify handle"></i>
                        <div class="row">
                          <div class="col-md-4">
                            <el-form-item label="Value">
                              <el-input v-model="element.value"/>
                            </el-form-item>
                          </div>
                          <div class="col-md-4" v-if="element.price">
                            <el-form-item label="Price">
                              <el-input v-model="element.price"/>
                            </el-form-item>
                          </div>
                          <div class="col-md-3">
                            <el-checkbox v-model="element.is_default" label="Default" size="large"/>
                          </div>
                        </div>

                      </li>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

  <wallet-transactions-list v-if="type == 'view' && walletPermission" :id="walletProviderId" :url="walletUrl" :wallet-options="walletOptions" :enable-make-transaction="true"></wallet-transactions-list>

</template>

<script>
import {defineComponent, ref, toRefs, watch} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import {useRouter} from 'vue-router'
import draggable from "vuedraggable";
import WalletTransactionsList from "@/components/admin/wallet/WalletTransaction";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "provider-comp",
  components: {ImageComp, draggable,WalletTransactionsList},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref([]);
    const isIndeterminate = ref(true);
    const documentStatuses = ref([]);

    const countries = ref([]);
    const phoneCodes = ref([]);
    const loadingCities = ref(false);

    const walletProviderId = ref(null);
    walletProviderId.value = route.params.id;
    const walletUrl = APIs.WALLET.provider_transactions(walletProviderId.value);
    const walletPermission = checkPermission('providers.wallet')
    const walletOptions = ref([]);
    objData.value = {
      first_name: '',
      last_name: '',
      email: '',
      phone_code: '',
      phone: '',
      city_ids: [],
      image: '',
      password: '',
      is_active: true,

      identity_card: '',
      identity_card_expire_at: '',
      identity_card_status: '',

      driver_license: '',
      driver_license_expire_at: '',
      driver_license_status: '',

      vehicle_license: '',
      vehicle_license_expire_at: '',
      vehicle_license_status: ''
    };

    const validateProviderRate = (rule, value, callback) => {
      if (!(value >= 1 && value <= 100)) {
        callback(new Error('Rate should be between 1 and 100'))
      } else {
        callback()
      }
    }
    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },

      ],
      rate: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
        {
          validator: validateProviderRate,
          trigger: "change",
        }

      ],
      password: [
        {
          min: 6,
          message: "Length should greater than 6",
          trigger: "blur",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.PROVIDER.create);
            countries.value = response.data.data.countries;
            phoneCodes.value = response.data.data.phone_codes;
            documentStatuses.value = response.data.data.document_statuses;

          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.PROVIDER.edit(route.params.id));
            objData.value = response.data.data.provider;
            countries.value = response.data.data.countries;
            phoneCodes.value = response.data.data.phone_codes;
            documentStatuses.value = response.data.data.document_statuses;

          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.PROVIDER.show(route.params.id));
            objData.value = response.data.data.provider;
            countries.value = response.data.data.countries;
            phoneCodes.value = response.data.data.phone_codes;
            documentStatuses.value = response.data.data.document_statuses;
            walletOptions.value = response.data.data.wallet_options;
          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      formData.append('city_ids', JSON.stringify(getValueCityIds(objData.value.city_ids)));

      loading.value = true;
      ApiAxiosService.post(APIs.PROVIDER.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'providers-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      formData.append('city_ids', JSON.stringify(getValueCityIds(objData.value.city_ids)));

      loading.value = true;
      formData.append('_method', 'put');
      ApiAxiosService.post(APIs.PROVIDER.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'providers-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }

    const getValueCityIds = (value) => {
      let valueIds = [];
      value.forEach(function (item) {
        console.log(item);
        valueIds.push(Array.isArray(item) ? item[item.length - 1] : item);
      });
      return valueIds;
    }
    // watch(() => objData.value.country_id, async (currentValue, oldValue) => {
    //   loadingCities.value = true;
    //   cities.value = [];
    //   cities.value = await getCities(currentValue);
    //   if (!cities.value.find(el => el.id == objData.value.city_id)) {
    //     objData.value.city_id = "";
    //   }
    //   loadingCities.value = false;
    // });
    const disabledDate = (time) => {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date.getTime()
    }
    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      isIndeterminate,

      countries,
      phoneCodes,
      loadingCities,

      documentStatuses,

      walletProviderId,
      walletUrl,
      walletOptions,
      walletPermission,
      disabledDate
    };
  },

});
</script>


<style>
.selected-item {
  background-color: #bfffcc;
}
</style>